import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import HomeScreen from '../components/HomeScreen/HomeScreen';
import Navbar from '../components/Navbar/Navbar';

const AppRouter = () => {
	return (
		<Router>
			<div>
				<Navbar />

				<Switch>
					<Route exact path="/" component={HomeScreen} />
				</Switch>
			</div>
		</Router>
	);
};

export default AppRouter;
