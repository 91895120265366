import React from 'react';

const BenefitScreen = () => {
	return (
		<section id="beneficios" className="container-beneficios col-12 d-flex justify-content-center">
			<div className="content-beneficios col-10 col-xl-8 d-flex justify-content-between flex-wrap">
				<div className="content-info col-12">
					<div className="title">
						<hr className="liner" />
						<h2>BENEFICIOS</h2>
					</div>
					<div className="body">
						<div className="body-item">
							<div className="icono">
								<i className="fas fa-cog"></i>
							</div>
							<div className="title2">
								<h4>SIN INSTALACIÓN</h4>
							</div>
							<div className="body2">
								<p>Lo único que necesitas es un computador con acceso a internet.</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="fas fa-dollar-sign"></i>
							</div>
							<div className="title2">
								<h4>LOS MEJORES PRECIOS</h4>
							</div>
							<div className="body2">
								<p>La única plataforma con los precios más bajos del mercado con vigencia anual y mensual.</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="fas fa-users"></i>
							</div>
							<div className="title2">
								<h4>FÁCIL MANEJO</h4>
							</div>
							<div className="body2">
								<p>Disponibilidad 24-7 con soporte técnico y asesoramiento.</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="fas fa-clock"></i>
							</div>
							<div className="title2">
								<h4>AHORRO DE TIEMPO</h4>
							</div>
							<div className="body2">
								<p>
									Con solo un clic creas la factura y emites automáticamente la factura de compensación a la operadora y
									la retención en menos de 1 minuto.
								</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="fas fa-thumbs-up"></i>
							</div>
							<div className="title2">
								<h4>REDUCCIÓN DE ERRORES</h4>
							</div>
							<div className="body2">
								<p>
									Con TransporTech olvídate de los procesos manuales llevando el control total de los puntos de emisión
									de los socios.
								</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="fas fa-laptop"></i>
							</div>
							<div className="title2">
								<h4>INTERFAZ AMIGABLE</h4>
							</div>
							<div className="body2">
								<p>
									El sistema te permitirá facturar desde cualquier dispositivo con conexión a internet con 3 sencillos
									pasos.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default BenefitScreen;
