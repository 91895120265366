import React from 'react';
import BgServicio from '../../assets/img/servicios.png';

const ServiceScreen = () => {
	return (
		<section id="servicios" className="container-service col-12 d-flex justify-content-center">
			<div className="content-service col-10 col-xl-8 d-flex justify-content-between flex-wrap">
				<div className="content-info col-12">
					<div className="title">
						<hr className="liner" />
						<h2>SERVICIOS</h2>
					</div>
					<div className="body mt-5 row m-0">
						<div className="left col-12 col-lg-4">
							<div className="body-item">
								<div className="content">
									<h4>Facturas</h4>
									<p>Crea tus facturas y automáticamente se autorizan por el SRI</p>
								</div>
								<div className="icono">
									<i className="fas fa-check-circle"></i>
								</div>
							</div>

							<div className="body-item">
								<div className="content">
									<h4>Notas de Crédito</h4>
									<p>Crea notas de créditos desde los puntos de emisión y operadoras</p>
								</div>
								<div className="icono">
									<i className="fas fa-check-circle"></i>
								</div>
							</div>
							<div className="body-item">
								<div className="content">
									<h4>Facturas de Puntos de Emisión</h4>
									<p>
										Se le asigna un punto de emisión diferente a cada socio, podrán emitir la factura con su RUC
										personal hacia la compañía para la compensación de facturas
									</p>
								</div>
								<div className="icono">
									<i className="fas fa-check-circle"></i>
								</div>
							</div>
						</div>
						<div className="center col-12 col-lg-4 p-4">
							<img className="footer-img" src={BgServicio} alt="Servicios de TransportTech" />
						</div>
						<div className="right col-12 col-lg-4">
							<div className="body-item">
								<div className="icono">
									<i className="fas fa-check-circle"></i>
								</div>
								<div className="content content-2">
									<h4>Retenciones</h4>
									<p>A facturas recibidas por compras o servicios contratados</p>
								</div>
							</div>
							<div className="body-item">
								<div className="icono">
									<i className="fas fa-check-circle"></i>
								</div>
								<div className="content content-2">
									<h4>Facturas de Compensación</h4>
									<p>Automáticamente el sistema emite la factura personal de compensación a la operadora</p>
								</div>
							</div>
							<div className="body-item">
								<div className="icono">
									<i className="fas fa-check-circle"></i>
								</div>
								<div className="content content-2">
									<h4>Reportes</h4>
									<p>Genera reportes diarios, mensuales sobre las facturas y ventas</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServiceScreen;
