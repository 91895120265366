import React from 'react';
import BgCaracteristicas from '../../assets/img/caracteristicas.png';

const FeaturesScreen = () => {
	return (
		<section id="caracteristicas" className="container-feature col-12 d-flex justify-content-center">
			<div className="content-feature col-10 col-xl-8 d-flex justify-content-between flex-wrap">
				<div className="content-info col-12">
					<div className="title">
						<hr className="liner" />
						<h2>CARACTERÍSTICAS</h2>
					</div>
					<div className="body mt-5">
						<div className="body-item">
							<div className="icono">
								<i className="far fa-check-circle"></i>
							</div>
							<div className="content">
								<h4>Expertos en la Ley 295 del SRI</h4>
								<p>
									Evita multas, reduce el riesgo tributario y fiscal que puedan generar la mala administración de los
									puntos de emisión y operadoras (resolución 295 capítulo 1 y 2).
								</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="far fa-check-circle"></i>
							</div>
							<div className="content">
								<h4>Expertos en gestión y automatización de procesos</h4>
								<p>
									Lleva el control de tu compañía, podrás activar y desactivar los puntos de emisión y automatizar los
									procesos de facturación electrónica.
								</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="far fa-check-circle"></i>
							</div>
							<div className="content">
								<h4>100% en la Nube</h4>
								<p>
									Reduce costos en creación de facturero físico, ahorra tiempo y accede a toda la información en
									cualquier momento con solo un clic.
								</p>
							</div>
						</div>
						<div className="body-item">
							<div className="icono">
								<i className="far fa-check-circle"></i>
							</div>
							<div className="content">
								<h4>Listo para usar en menos de 48 horas</h4>
								<p>
									Empieza a utilizar el sistema en menos de 48 horas, con capacitación inicial y arranque. Te ayudamos
									en todo el proceso inicial.
								</p>
							</div>
						</div>
					</div>
					<div className="center col-12 d-flex justify-content-center align-items-center">
						<img className="content-info-img" src={BgCaracteristicas} alt="Características de TransportTech" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default FeaturesScreen;
