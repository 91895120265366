import React from 'react';
import BG1 from '../../assets/img/bg-1.png';
import BG2 from '../../assets/img/bg-2.png';
import BG3 from '../../assets/img/bg-3.png';
import BG4 from '../../assets/img/bg-4.png';

const BannerComponent = () => {
	return (
		<section id="inicio" className="carousel-container">
			<div id="carouselExampleIndicators" className="carousel slide h-100" data-bs-ride="carousel">
				<div className="carousel-indicators">
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="0"
						className="active"
						aria-current="true"
						aria-label="Slide 1"
					></button>
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="1"
						aria-label="Slide 2"
					></button>
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="2"
						aria-label="Slide 3"
					></button>
					<button
						type="button"
						data-bs-target="#carouselExampleIndicators"
						data-bs-slide-to="3"
						aria-label="Slide 4"
					></button>
				</div>
				<div className="carousel-inner">
					<div className="carousel-item active">
						<img src={BG1} className="d-block h-100" alt="Imagen decreto 295" />
					</div>
					<div className="carousel-item h-100">
						<img src={BG2} className="d-block h-100" alt="Imagen sistema de gestión y facturación electrónica" />
					</div>
					<div className="carousel-item h-100">
						<img src={BG3} className="d-block h-100" alt="Imagen Transportech" />
					</div>
					<div className="carousel-item h-100">
						<img src={BG4} className="d-block h-100" alt="Imagen Transportech" />
					</div>
				</div>
				<button
					className="carousel-control-prev"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="prev"
				>
					<span className="carousel-control-prev-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Previous</span>
				</button>
				<button
					className="carousel-control-next"
					type="button"
					data-bs-target="#carouselExampleIndicators"
					data-bs-slide="next"
				>
					<span className="carousel-control-next-icon" aria-hidden="true"></span>
					<span className="visually-hidden">Next</span>
				</button>
			</div>
		</section>
	);
};

export default BannerComponent;
