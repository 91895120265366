import React, { useState } from 'react';
import axios from 'axios';
import { Maps } from './Maps';

const ContactScreen = () => {
	const [datos, setDatos] = useState({
		username: '',
		email: '',
		phone: '',
		messageUser: '',
	});

	const [confMap] = useState({
		lat: -1.0045210404153875,
		lng: -80.71078730294869,
		apiKey: 'AIzaSyDH2L4q3Sm_k2Rn8j1xWw6bg2YYq7TS3k0',
		zoom: 16,
	});

	const [state, setState] = useState(false);

	const handleInputChange = (e) => {
		setDatos({
			...datos,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const resp = await axios.post(`http://api-transportech.quotech.ec/api/v1/usuarios/contacto`, datos);
		console.log(resp.data.ok);
		if (resp.data.ok) {
			e.target.reset();
			setState(true);

			setTimeout(() => {
				setState(false);
			}, 3000);
		}
	};

	return (
		<>
			<section id="contacto" className="container-contact col-12 d-flex justify-content-center">
				<div className="content-contact col-10 col-xl-8 d-flex justify-content-between flex-wrap">
					<div className="content-info col-12">
						<div className="title">
							<hr className="liner" />
							<h2>CONTÁCTENOS</h2>
						</div>
						<div className="body row col-12 m-0">
							<div className="form col-12 col-lg-6 mb-5">
								<form onSubmit={handleSubmit}>
									<div className="mb-4">
										<label for="exampleFormControlInput1" className="form-label">
											Nombres y apellidos
										</label>
										<input
											type="text"
											className="form-control p-3"
											id="exampleFormControlInput1"
											placeholder="Nombres y apellidos"
											name="username"
											onChange={handleInputChange}
											required
										/>
									</div>
									<div className="mb-4">
										<label for="exampleFormControlInput1" className="form-label">
											Correo electrónico
										</label>
										<input
											type="email"
											className="form-control p-3"
											id="exampleFormControlInput1"
											placeholder="Correo electrónico"
											name="email"
											onChange={handleInputChange}
											required
										/>
									</div>
									<div className="mb-4">
										<label for="exampleFormControlInput1" className="form-label">
											Número de teléfono
										</label>
										<input
											type="text"
											className="form-control p-3"
											id="exampleFormControlInput1"
											placeholder="Número de teléfono"
											name="phone"
											onChange={handleInputChange}
											required
										/>
									</div>
									<div className="mb-4">
										<label for="exampleFormControlTextarea1" className="form-label">
											Mensaje
										</label>
										<textarea
											className="form-control p-3"
											id="exampleFormControlTextarea1"
											rows="5"
											placeholder="Mensaje"
											name="messageUser"
											onChange={handleInputChange}
											required
										></textarea>
									</div>
									<div className="col-12 mt-4">
										<button className="btn btn-danger" type="submit">
											Enviar mensaje
										</button>
									</div>
								</form>
							</div>
							<div className="form col-12 col-lg-6">
								<Maps {...confMap} />
							</div>
						</div>
					</div>
				</div>
			</section>

			{state ? (
				<div class="alert alert-success m-0 col-12 d-flex justify-content-center" role="alert">
					<h5>Mensaje enviado correctamente.</h5>
				</div>
			) : null}
		</>
	);
};

export default ContactScreen;
