import React from 'react';
import Logo from '../../assets/img/logob.png';

const FooterScreen = () => {
	return (
		<footer className="container-footer col-12 d-flex justify-content-center">
			<div className="content-footer col-10 col-xl-8 row m-0">
				<div className="left col-12 col-lg-6 p-3">
					<img className="footer-img" src={Logo} alt="Logo de TransportTech" />
					<p className="footer-text">TransporTech, expertos en facturación electrónica para compañías de transporte.</p>
				</div>
				<div className="right col-12 col-lg-6 p-3">
					<h5>Contacto</h5>
					<div className="col-12 contact-list">
						<div className="contact-item">
							<i className="fas fa-map-marker-alt"></i>
							<h6>Manta, ciudadela Santa Isabel</h6>
						</div>
						<div className="contact-item">
							<i className="fas fa-phone-alt"></i>
							<h6>+593 99 182 2731</h6>
						</div>
						<div className="contact-item">
							<i className="fas fa-envelope"></i>
							<h6>info@quotech.ec</h6>
						</div>
					</div>
				</div>
				<hr />
				<p className="copy">
					Copyright © 2021{' '}
					<a rel="noreferrer" target="_blank" href="https://quotech.ec/">
						QuoTech
					</a>{' '}
					Todos los derechos reservados
				</p>
			</div>
		</footer>
	);
};

export default FooterScreen;
