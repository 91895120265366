import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';

import './Navbar.css';

const Navbar = () => {
	return (
		<header className="navbar-container col-12 d-flex justify-content-center">
			<nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light p-0 col-12 flex-nowrap">
				<div className="container-fluid h-100 col-12 p-0 justify-content-around">
					<NavLink
						to="/"
						className="d-flex justify-content-center align-items-center col-4 col-sm-6 col-md-4 col-lg-2 h-100"
					>
						<img className="col-12 col-sm-8 h-100 navbar-img" src={Logo} alt="Logo de TransportTech" />
					</NavLink>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="offcanvas"
						data-bs-target="#offcanvasNavbar"
						aria-controls="offcanvasNavbar"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div
						className="offcanvas offcanvas-end col-12 h-100 d-flex justify-content-center align-items-end"
						tabindex="-1"
						id="offcanvasNavbar"
						aria-labelledby="offcanvasNavbarLabel"
					>
						<div class="offcanvas-header col-12">
							<h5 class="offcanvas-title" id="offcanvasNavbarLabel">
								MENÚ
							</h5>
							<button
								type="button"
								className="btn-close text-reset"
								data-bs-dismiss="offcanvas"
								aria-label="Close"
							></button>
						</div>
						<div className="offcanvas-body">
							<div className="navbar-nav" id="navbarNavAltMarkup">
								<div className="nav-item container-menu navbar-nav justify-content-evenly align-items-center">
									<a href="#inicio" className="nav-item nav-link fs-6 p-3">
										Inicio
									</a>
									<a href="#beneficios" className="nav-item nav-link fs-6 p-3">
										Beneficios
									</a>
									<a href="#servicios" className="nav-item nav-link fs-6 p-3">
										Servicios
									</a>
									<a href="#precios" className="nav-item nav-link fs-6 p-3">
										Precios
									</a>
									<a href="#caracteristicas" className="nav-item nav-link fs-6 p-3">
										Características
									</a>
									<a href="#contacto" className="nav-item nav-link fs-6 p-3">
										Contacto
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</header>
	);
};

export default Navbar;
