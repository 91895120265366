import React from 'react';
import BannerComponent from './BannerComponent';
import QuienesSomos from './QuienesSomos';
import BenefitScreen from '../BenefitScreen/BenefitScreen';
import ServiceScreen from '../ServiceScreen/ServiceScreen';
import PriceScreen from '../PriceScreen/PriceScreen';
import FeaturesScreen from '../FeaturesScreen/FeaturesScreen';
import ContactScreen from '../ContactScreen/ContactScreen';
import FooterScreen from '../FooterScreen/FooterScreen';
import '../../App.css';

const HomeScreen = () => {
	return (
		<>
			<BannerComponent />
			<QuienesSomos />
			<BenefitScreen />
			<ServiceScreen />
			<PriceScreen />
			<FeaturesScreen />
			<ContactScreen />
			<FooterScreen />

			{/* Botón de whatsapp */}
			<a href="https://api.whatsapp.com/send?phone=+593978825170" className="btn-wsp" target="noreferrer">
				<i className="fab fa-whatsapp icono"></i>
			</a>
		</>
	);
};

export default HomeScreen;
