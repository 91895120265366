import React from 'react';
import BgQuienesSomos from '../../assets/img/quienessomos.png';
import BgQuienesSomos2 from '../../assets/img/quienessomos2.png';
import BgQuienesSomos3 from '../../assets/img/quienessomos3.png';

const QuienesSomos = () => {
	return (
		<section className="container-quienes-somos col-12 d-flex justify-content-center">
			<div className="content-quienes-somos col-10 col-xl-8 d-flex justify-content-between flex-wrap">
				<div className="content-info col-12 col-xl-5">
					<hr className="liner" />
					<h2>QUIENES SOMOS</h2>
					<p className="texto">
						<strong>TransporTech</strong> es un software de facturación electrónica para compañías de transporte,
						nuestra misión es automatizar procesos de facturación de los socios de la compañía de transporte mediante
						puntos de emisión, creando las facturas de compensación y retención de forma automática.
					</p>
				</div>
				<div className="content-img col-12 col-xl-6">
					<div id="carouselExampleIndicatorss" className="carousel slide h-100" data-bs-ride="carousel">
						<div className="carousel-indicators">
							<button
								type="button"
								data-bs-target="#carouselExampleIndicatorss"
								data-bs-slide-to="0"
								className="active"
								aria-current="true"
								aria-label="Slide 1"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicatorss"
								data-bs-slide-to="1"
								aria-label="Slide 2"
							></button>
							<button
								type="button"
								data-bs-target="#carouselExampleIndicatorss"
								data-bs-slide-to="2"
								aria-label="Slide 3"
							></button>
						</div>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img src={BgQuienesSomos} className="d-block h-100" alt="Imagen Transportech" />
							</div>
							<div className="carousel-item h-100">
								<img src={BgQuienesSomos2} className="d-block h-100" alt="Imagen Transportech" />
							</div>
							<div className="carousel-item h-100">
								<img src={BgQuienesSomos3} className="d-block h-100" alt="Imagen Transportech" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default QuienesSomos;
