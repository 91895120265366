import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = () => <i className="fas fa-map-marker-alt map"></i>;

export const Maps = ({ lat, lng, apiKey, zoom }) => {
	return (
		<div className="googleMap">
			<GoogleMapReact
				bootstrapURLKeys={{
					key: apiKey,
					libraries: ['places', 'geometry', 'drawing', 'visualization'],
				}}
				defaultCenter={{
					lat,
					lng,
				}}
				defaultZoom={zoom}
				yesIWantToUseGoogleMapApiInternals
			>
				<AnyReactComponent lat={lat} lng={lng} />
			</GoogleMapReact>
		</div>
	);
};
